import React,{useEffect} from "react"
import { Footer } from "./components/footer/Footer"
import { Header } from "./components/header/Header"
// import { Home } from "./pages/home/Home"
// import { Login } from "./pages/login/Login"
// import { Regsiter } from "./pages/login/Regsiter"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { ReactDetailPages } from "./pages/details/reactDetailPages"
import { NodeDetailPages } from "./pages/details/nodeDetailPages"
import { CppDetailPages } from "./pages/details/cppDetailPages"
import { DataScienceDetailPages } from "./pages/details/dataScienceDetailPages"
import { DsaDetailPages } from "./pages/details/dsaDetailPages"
import { HtmlDetailPages } from "./pages/details/htmlDetailPages"
import { JavaDetailPages } from "./pages/details/javaDetailPages"
import { JavascriptDetailPages } from "./pages/details/javascriptDetailPages"
import { MongodbDetailPages } from "./pages/details/mongodbDetailPages"
import { PythonDetailPages } from "./pages/details/pythonDetailPages"
import { SnowflakeDetailPages } from "./pages/details/snowflakeDetailPages"
import { SqlDetailPages } from "./pages/details/sqlDetailPages"
import { CssDetailPages } from "./pages/details/cssDetailPages"
// import { Account } from "./pages/account/Account"
import { About } from "./components/about/About"
// import { Contact } from "./components/contact/contact"
import { Node } from "./pages/home/node"
import { Angular } from "./pages/home/angular"
import { Cpp } from "./pages/home/cpp"
import { DataScience } from "./pages/home/dataScience"
import { Dsa } from "./pages/home/dsa"
import { Html } from "./pages/home/html"
import { Java } from "./pages/home/java"
import { Javascript } from "./pages/home/javascript"
import { Mongodb } from "./pages/home/mongodb"
import { Python } from "./pages/home/python"
import { Snowflake } from "./pages/home/snowflake"
import { Sql } from "./pages/home/sql"
import { Css } from "./pages/home/css"
import { Reactjs } from "./pages/home/react"
import { AngularDetailsPages } from "./pages/details/angularDetailPages"
import { QuickLinks } from "./components/quickLinks/quickLink"

const App = () => {
  // to not show error or warning in console
  useEffect(() => {
    console.error = () => {}; // Suppresses console.error messages
    console.warn = () => {}; // Suppresses console.warn messages
  }, []);
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route exact path='/' component={About} />
          {/* <Route exact path='/login' component={Login} /> */}
          {/* <Route exact path='/register' component={Regsiter} /> */}
          {/* <Route exact path='/account' component={Account} /> */}
          {/* <Route exact path='/contact' component={Contact} /> */}


          <Route exact path='/react-interview-questions' component={Reactjs} />
          <Route exact path='/react-interview-questions/:id' component={ReactDetailPages} />
          <Route exact path='/node-express-interview-questions' component={Node} />
          <Route exact path='/node-express-interview-questions/:id' component={NodeDetailPages} />
          <Route exact path='/angular' component={Angular} />
          <Route exact path='/angular/:id' component={AngularDetailsPages} />
          <Route exact path='/cpp' component={Cpp} />
          <Route exact path='/cpp/:id' component={CppDetailPages} />
          <Route exact path='/dataScience' component={DataScience} />
          <Route exact path='/dataScience/:id' component={DataScienceDetailPages} />
          <Route exact path='/dsa' component={Dsa} />
          <Route exact path='/dsa/:id' component={DsaDetailPages} />
          <Route exact path='/html' component={Html} />
          <Route exact path='/html/:id' component={HtmlDetailPages} />
          <Route exact path='/java' component={Java} />
          <Route exact path='/java/:id' component={JavaDetailPages} />
          <Route exact path='/javascript-interview-questions' component={Javascript} />
          <Route exact path='/javascript-interview-questions/:id' component={JavascriptDetailPages} />
          <Route exact path='/mongodb-interview-questions' component={Mongodb} />
          <Route exact path='/mongodb-interview-questions/:id' component={MongodbDetailPages} />
          <Route exact path='/python' component={Python} />
          <Route exact path='/python/:id' component={PythonDetailPages} />
          <Route exact path='/snowflake' component={Snowflake} />
          <Route exact path='/snowflake/:id' component={SnowflakeDetailPages} />
          <Route exact path='/sql' component={Sql} />
          <Route exact path='/sql/:id' component={SqlDetailPages} />
          <Route exact path='/css' component={Css} />
          <Route exact path='/css/:id' component={CssDetailPages} />
          


        </Switch>
        <QuickLinks />
        <Footer />
      </Router>
    </>
  )
}
export default App
