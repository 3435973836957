import React from "react";
import "./About.css";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { nav } from "../../assets/data/data";


export const About = () => {
  return (
    <div className='about_container'>
      <section className='newPost'>
        <div className='boxItems'>
          <h1 className="animatedHeader">Disclaimer</h1>
          <p>
            Welcome to our vibrant educational platform! We provide a curated collection of resources and insights primarily for learning and informational purposes. The content presented here is not intended to replace professional advice or guidance. We encourage users to supplement their learning with additional resources and consult professionals in the respective fields for comprehensive knowledge.
          </p>

          <h2 className="animatedHeader">Explore Our Knowledge Base</h2>
          <p>
            Delve into an extensive repository of articles, tutorials, and interview questions meticulously curated by experts in the fields of React.js, Node.js, and MongoDB. Our platform covers a broad spectrum of topics, catering to beginners and seasoned professionals alike, ensuring a comprehensive understanding of these technologies.
            <br /><br />
            <div className="links-row">
              {/* <Link to="/react-interview-questions" className="custom-link">
                <span className="goto-icon">↗</span> React.js Questions
              </Link>
              <Link to="/node-express-interview-questions" className="custom-link">
                <span className="goto-icon">↗</span> Node.js Questions
              </Link>
              <Link to="/mongodb-interview-questions" className="custom-link">
                <span className="goto-icon">↗</span> MongoDB Questions
              </Link> */}
            {nav.map((link) => (
              <Link  to={link.url} className="custom-link">
                    <span className="goto-icon">↗</span>   {link.page}
              </Link>
              ))}
            </div>
          </p>

          <h2 className="animatedHeader">Why Choose Us?</h2>
          <p>
            Our platform stands out with real-world scenarios and questions asked by top tech companies during interviews. Get acquainted with questions aligned with industry standards and gain confidence in your interview preparations.
          </p>

          <h2 className="animatedHeader">Comprehensive Learning</h2>
          <p>
            Dive into diverse topics within React.js, Node.js, and MongoDB. Our collection spans various frameworks, best practices, and use cases, enabling a thorough grasp of these technologies' nuances.
          </p>

          <h2 className="animatedHeader">Effortless Navigation</h2>
          <p>
            Enjoy a user-friendly interface designed for seamless exploration. Navigate effortlessly through our extensive database of resources and interview questions.
          </p>

          <h2 className="animatedHeader">Stay Updated</h2>
          <p>
            Keep pace with the latest trends in React.js, Node.js, and MongoDB. Our team diligently updates the platform to reflect emerging technologies and evolving industry standards.
          </p>

          <h2 className="animatedHeader">Our Diverse Audience</h2>
          <p>
            Our platform caters to individuals at all levels of expertise. Whether you're a recent graduate, a seasoned professional, or an enthusiast seeking to enhance your knowledge, our resources are tailored to suit your learning needs.
          </p>

          {/* <h2 className="animatedHeader">Get Started Now</h2>
          <p>
            <div className="links-row">
              <Link to="/react-interview-questions" className="custom-link">
                <span className="goto-icon">↗</span> React.js Questions
              </Link>
              <Link to="/node-express-interview-questions" className="custom-link">
                <span className="goto-icon">↗</span> Node.js Questions
              </Link>
              <Link to="/mongodb-interview-questions" className="custom-link">
                <span className="goto-icon">↗</span> MongoDB Questions
              </Link>
            </div>
          </p> */}
          
          
        </div>
      </section>
      <Helmet>
      <title>Interview Questions for Node.js, React.js and MongoDB | InterviewCraze</title>
      <meta name="description" content="Land your dream job with InterviewCraze. Dive into a curated collection of React.js, Node.js, & MongoDB resources. Get expert tips, articles, & interview questions." />
      </Helmet>
    </div>
  );
};

export default About;
