import React from "react";
import "./quickLinks.css"; // Import the CSS file for styling
import { nav } from "../../assets/data/data"
import { Link } from "react-router-dom";


export const QuickLinks = () => {


  return (
    <div className="quick-links">
		<p>Crack More Interview Question !!</p><br/>
              {/* <Link to="/react-interview-questions" className="custom-link">
                <span className="goto-icon">↗</span> React Interview Questions
              </Link>
              <Link to="/node-express-interview-questions" className="custom-link">
                <span className="goto-icon">↗</span> Node.js Interview Questions
              </Link>
              <Link to="/mongodb-interview-questions" className="custom-link">
                <span className="goto-icon">↗</span> MongoDB Interview Questions
              </Link> */}
              {nav.map((link) => (
              <Link  to={link.url} className="custom-link">
                    <span className="goto-icon">↗</span>   {link.page}
              </Link>
              ))}
              
    </div>
  );
};

