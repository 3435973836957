import React, { useState } from "react";
import "./blog.css";
import { node } from "../../assets/data/node";
import { AiOutlineTags, AiOutlineClockCircle, AiOutlineComment, AiOutlineShareAlt } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';


export const NodeCard = () => {
  // Define the number of cards per page
  const cardsPerPage = 27;

  // Initialize the current page state
  const [currentPage, setCurrentPage] = useState(1);

  // Sort the blog data by date in descending order (latest date first)
  const sortedBlog = [...node].sort((a, b) => {
    const idA = a.id;
    const idB = b.id;
    return idA - idB;
  });

  // Calculate the index range for the cards to display on the current page
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;

  // Filter the sorted blog data based on the current page
  const currentBlogData = sortedBlog.slice(startIndex, endIndex);

  // Function to handle pagination
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <section className="blog">
      <h1>Node.js Interview Questions</h1>
        <div className="container grid3">
          {currentBlogData.map((item) => (
            <div className="box boxItems" key={item.id}>
              <div className="img">
                {/* <img src={item.cover} alt="" /> */}
              </div>
              <div className="details">
                <div className="tag">
                  <AiOutlineTags className="icon" />
                  <p style={{ color: 'blue' }}>{item.category}</p>
                </div>
                <Link to={`/node-express-interview-questions/${item.title.substring(3).replace(/ /g, '-')}-${item.id}`} className="link">
                  <h3>{item.title}</h3>
                </Link>
                {/* <p>{item.desc.slice(0, 180)}...</p> */}
                {/* <div className="date">
                  <AiOutlineClockCircle className="icon" /> <label htmlFor="">{item.date}</label>
                  <AiOutlineComment className="icon" /> <label htmlFor="">27</label>
                  <AiOutlineShareAlt className="icon" /> <label htmlFor="">SHARE</label>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* Pagination */}
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={endIndex >= sortedBlog.length}
        >
          Next
        </button>
      </div>
      <Helmet>
          <meta
            name='keywords'
            content="node js interview questions, node js developer interview questions, node interview questions"
          />
          <title>Node Js Developer Interview Questions | Interviewcraze</title>
          <meta name="description" content="Master your node js developer interview questions with our comprehensive Q&A collection at InterviewCraze. we provide 200+ Techincal Interview questions. Visit Now!" />

        </Helmet>
    </>
  );
};
