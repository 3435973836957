import React from "react"
import { CppCard } from "../../components/blog/cppCard"
import { Category } from "../../components/category/Category"

export const Cpp = () => {
  return (
    <>
      {/*  <Slider />*/}
      <Category />
      <CppCard />
    </>
  )
}